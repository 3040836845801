<template>
  <div class="header__desktop">
    <div class="header__main row">
      <div class="column">
        <nav>
          <ul>
            <Dropdown type="shop" :items="links['shop']" :openOnHover="dropdownOnHover" />
          </ul>
        </nav>
      </div>
      <div class="column">
        <nav>
          <ul>
            <Dropdown type="learn" :items="links['learn']" :openOnHover="dropdownOnHover" />
            <Dropdown type="studios" :items="links['studios']" :openOnHover="dropdownOnHover" />
          </ul>
        </nav>

        <ul>
          <li><button @click="openHeaderMenuExpand('search')"><SvgIcon name="search" title="Search" /></button></li>
          <li><Account /></li>
          <li><button @click="openHeaderMenuExpand('cart')"><span class="cart-items">{{ numCartItems }} <span class="accessible">items in bag</span></span><SvgIcon name="bag" /></button></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';

  export default {
    name: 'Desktop',

    props: {
      dropdownOnHover: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      SvgIcon: () => import('@/components/global/SvgIcon'),
      Account: () => import('@/components/header/Account'),
      Dropdown: () => import('@/components/header/Dropdown'),
    },

    computed: {
      ...mapState({
          links: state => state.header.links,
          cartItems: state => state.cart.items
      }),
      ...mapGetters([
        'numCartItems'
      ])
    },

    methods: {
      ...mapActions([
        'openHeaderMenuExpand'
      ])
    }
  }
</script>
